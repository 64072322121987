import "./Styles/_App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDetectAdBlock } from "adblock-detect-react";
import BrowserDetection from 'react-browser-detection';

import Dashboard from "./Pages/Dashboard";
import Landing from "./Pages/Landing";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";


function App() {
  const adBlockDetected = useDetectAdBlock();
  const [usingAdblock, setUsingAdblock] = useState(false);
  const [blockVisit, setBlockVisit] = useState(false);

  useEffect(() => {
    setUsingAdblock(adBlockDetected);
    const browserHandler = {
      default: (browser) => { console.log(browser); setBlockVisit(true); }
    };

    console.log(browserHandler);
  }, []);

  const checkAgain = () => { 
    setUsingAdblock(adBlockDetected); 
  
  };

  if(adBlockDetected){
    return (
      <>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={true}>
            <Modal.Body className="text-center">
              <h3 class="title">AdBlocker érzékelve!</h3>
              <p>
                A Weboldal motorja érzékelte, hogy Reklámblokkolót használsz, de a reklámok nélkül a weboldal fenntarthatósága igen nagy mértékben csökken!<br/>
                <br/>
                Amennyiben lehetőséged van rá, <b>Kapcsold ki</b> a reklámblokkolót.<br/>
                <br/>
                Amennyiben a lenti gomb nem működne, kérlek, töltsd újra az oldalt!
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={checkAgain}>Kikapcsoltam!</Button>
            </Modal.Footer>
        </Modal>
      </>
    );
  }

  if (!adBlockDetected){
    return (
      <>
        <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
          <Routes>
            <Route path="/*" element={<Dashboard />} />
            <Route path="/landing" element={<Landing />} />
          </Routes>
        </Router>
      </>
    );
  }
}

export default App;
