import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { validateLoginForm } from '../../Utils/FormValidation';
import { FacebookAuthProvider, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, signInWithFacebook, signInWithGoogle } from '../../Utils/Firebase';
import { checkfor2fa } from '../../Utils/2fa';
import { Form, Alert, Button, Col, FloatingLabel, Row } from 'react-bootstrap';
import FormErrorMessage from '../../Widgets/Global/FormErrorMessage';
import FaIcon from '../../Widgets/Global/FaIcon';
import { useUsers } from '../../Contexts/UserContext';
import { __getTimeStamp } from '../../Utils/TimeFuncs';
import { MyStorage } from '../../Utils/MyStorage';

const Login = () => {
    const navigate = useNavigate(null);
    const { updateUser, checkUser, createUser } = useUsers();
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [fields, setFields] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({ email: false, password: false, message: false, variant: 'danger' });
    const [have2FA, setHave2FA] = useState(false);
    const handleChange = (event) => {
        const { name, value } = event.target;
        setErrors((prev) => ({ ...prev, [name]: false }));
        setFields((prev) => ({ ...prev, [name]: value }));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        var payload = validateLoginForm(fields);
        if (payload.success) {
            signInWithEmailAndPassword(auth, fields.email, fields.password)
                .then(async (userCredential) => {
                    /*const twofa = await checkfor2fa(userCredential.user);
                    if (twofa) {
                        setHave2FA(true);
                    } else {*/
                        updateUser(userCredential.user.uid, { lastAction: __getTimeStamp() });
                        MyStorage.session.put('uid', userCredential.user.uid);
                        navigate("/");
                    /*};*/
                }).catch((err) => {
                    if(err.code === "auth/invalid-credential"){
                        setErrors((prev) => ({ ...prev, message: "Hibás e-mail és/vagy jelszó!", variant: "danger" }));
                    }
                    console.log(err.code);
                })
        } else {
            setErrors(payload.errors);
            setErrors((prev) => ({ ...prev, message: payload.message, variant: "danger" }));
        }
    }
    const loginWithProvider = async (provider) => {
        try {
            const result = (provider === "facebook") ? await signInWithFacebook() : await signInWithGoogle();
            if (result.user) {
                const haveUser = await checkUser(result.user.uid);
                if(haveUser){
                    const twofa = await checkfor2fa(result.user);
                    if (twofa) {
                        setHave2FA(true);
                    } else {
                        updateUser(result.user.uid, { lastAction: __getTimeStamp() });
                        MyStorage.session.put('uid', result.user.uid);
                        navigate("/");
                    };
                }else{
                    if(provider === "google"){
                        createUser(result.user.uid, {
                            avatar: result.user.photoURL,
                            email: result.user.email,
                            enabled2fa: false,
                            lastAction: __getTimeStamp(),
                            perm: 1,
                            uid: result.user.uid,
                            username: result.user.displayName,
                            provider: "Google"
                        });
                    }else if(provider === "facebook"){
                        const credential = FacebookAuthProvider.credentialFromResult(result);
                        const token = credential.accessToken;
                        const user = result.user;
                        const response = await fetch(`https://graph.facebook.com/me?fields=id,name,birthday,location,friends.limit(0).summary(true),picture.type(large)&access_token=${token}`);
                        const data = await response.json();
                        createUser(user.uid, {
                            avatar: data.picture.data.url,
                            email: user.email,
                            enabled2fa: false,
                            lastAction: __getTimeStamp(),
                            perm: 1,
                            uid: user.uid,
                            username: data.name,
                            provider: "Facebook"
                        });
                    };
                    MyStorage.session.put('uid', result.user.uid);
                    navigate("/");
                };
            };
        } catch (e) { console.error(e.code); }
    }

    /*2FA*/
    const [code, setCode] = useState({ 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' });
    const codeChange = (event) => {
        const { name, value } = event.target;
        const match = name.match(/\d+/);
        if (match) { 
            setCode((prev) => ({ ...prev, [match[0]]: value })); 
            if(match[0] <= 5){
                let element = document.getElementById(`code-${(parseInt(match[0]) + parseInt(1))}`);
                element.focus();
            };
        };
    }
    const submit2FA = (event) => {
        event.preventDefault();
        const _code = Object.values(code).join('')
        alert(_code);
    }

    return (
        <Row className="justify-content-center">
            <Col md={{ span: 6, offset: 0 }}>
                {(!have2FA) && (<Form onSubmit={handleSubmit}>
                    {(errors?.message) && (<Alert variant={errors?.variant}>{errors?.message}</Alert>)}
                    <FloatingLabel controlId='email' label="E-mail cím" className="mb-3">
                        <Form.Control ref={emailRef} className={(errors?.email) ? `is-invalid` : ``} type="email" placeholder="E-mail cím" name="email" value={fields.email} onChange={handleChange} />
                        <FormErrorMessage error={errors?.email} target={emailRef} />
                    </FloatingLabel>
                    <FloatingLabel controlId='password' label="Jelszó" className="mb-3">
                        <Form.Control ref={passwordRef} className={(errors?.password) ? `is-invalid` : ``} type="password" placeholder="Jelszó" name="password" value={fields.password} onChange={handleChange} />
                        <FormErrorMessage error={errors?.password} target={passwordRef} />
                    </FloatingLabel>
                    <Button variant="success" type="submit" className="mb-3 d-block w-100"> <FaIcon type="light" icon="sign-in-alt" /> Bejelentkezés </Button>
                    <Button variant="link" as={Link} to="/auth/fpw" className="mb-3 d-block w-100"> Elfelejtetted a jelszavad? </Button>
                    <hr />
                    <Button as={Link} to="/auth/register" variant="dark" style={{ marginRight: '5px' }}> Regisztráció </Button>
                    <Button variant="dark" style={{ marginRight: '5px' }} onClick={() => { loginWithProvider("facebook"); }}><FaIcon type="brands" icon="facebook-f" /></Button>
                    <Button variant="dark" style={{ marginRight: '5px' }} onClick={() => { loginWithProvider("google"); }}><FaIcon type="brands" icon="google" /></Button>
                </Form>)}
                {(have2FA) && (<>
                    <div className="mb-3 text-bold">Ezen a fiókon 2 faktoros hitelesítés van beállítva! Kérlek add meg a hitelesítőből a kódot!</div>
                    <Form onSubmit={submit2FA}>
                    {(errors?.message) && (<Alert variant={errors?.variant}>{errors?.message}</Alert>)}
                    <Form.Group className="d-flex mb-3">
                        <Form.Control type="text" style={{marginRight:"5px"}} maxLength={1} id="code-1" name="code[1]" value={code[1]} onChange={codeChange} />
                        <Form.Control type="text" style={{marginRight:"5px"}} maxLength={1} id="code-2" name="code[2]" value={code[2]} onChange={codeChange} />
                        <Form.Control type="text" style={{marginRight:"5px"}} maxLength={1} id="code-3" name="code[3]" value={code[3]} onChange={codeChange} />
                        <Form.Control type="text" style={{marginRight:"5px"}} maxLength={1} id="code-4" name="code[4]" value={code[4]} onChange={codeChange} />
                        <Form.Control type="text" style={{marginRight:"5px"}} maxLength={1} id="code-5" name="code[5]" value={code[5]} onChange={codeChange} />
                        <Form.Control type="text" style={{marginRight:"5px"}} maxLength={1} id="code-6" name="code[6]" value={code[6]} onChange={codeChange} />
                    </Form.Group>
                    <Button type="submit" variant="success"> Beküldés </Button>
                </Form>
                </>)}
            </Col>
        </Row>
    );
};

export default Login;