import { useEffect, useRef, useState } from "react";
import { useUsers } from "../../../Contexts/UserContext";
import { getUserByID } from "../../../Utils/FirebaseFuncs";
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../../Utils/Firebase";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import TBUserPicture from "../TopBar/TBUserPicture";
import { __getTimeStamp, getDiff } from "../../../Utils/TimeFuncs";
import FaIcon from "../../Global/FaIcon";
import TextWithLinks from "../../../Utils/URLFormatter";
import LoadingIndicator from "../../Global/LoadingIndicator";
import FeedItemComments from "./FeedItemComments";
import { shareOnFacebook } from "../../../Utils/Sharer";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Link } from "react-router-dom";

const FeedItem = ({ post }) => {
    const inputRef = useRef(null);
    const { user } = useUsers();
    const [isLoading, setIsLoading] = useState(false);
    const [poster, setPoster] = useState(false);
    const [target, setTarget] = useState(false);
    const [likes, setLikes] = useState(0);
    const [iliked, setIliked] = useState(false);
    const [atts, setAtts] = useState([]);
    const [lbIndex, setLBIndex] = useState(0);
    const [lightBoxOpen, setLightBoxOpen] = useState(false);

    const fetchPostMeta = async () => { 
        setIsLoading(true);
        try{
            const _user = await getUserByID(post.uid); 
            if(post.target_uid !== null && post.target_uid !== post.uid){
                const _target = await getUserByID(post.target_uid);
                setTarget(_target);
            }
            countLikes();
            iLiked();
            setPoster(_user);
        }catch(e) { console.error("Hiba:", e); }
        finally{ setIsLoading(false); };
    }
    const deletePost = async () => { const postRef = doc(db,"posts", post.id ); deleteDoc(postRef); await fetchPostMeta(); }
    const countLikes = async () => { const likesRef = collection(db,"posts",post.id,"likes"); const snapshot = await getDocs(likesRef); setLikes(snapshot.size); }
    const iLiked = async () => {const likesRef = doc(db, "posts", post.id, "likes", user.uid);const docSnap = await getDoc(likesRef);if(docSnap.exists()){ setIliked(true); }else{ setIliked(false);};}
    const likePost = async () => {const likesRef = doc(db, "posts", post.id, "likes", user.uid);const docSnap = await getDoc(likesRef);if(!docSnap.exists()){await setDoc(likesRef, {uid: user.uid,timestamp: __getTimeStamp()});}else{await deleteDoc(likesRef);};await fetchPostMeta();}
    const handleFocus = () => { if(inputRef.current) inputRef.current.focus(); }
    const sharePost = () => {
        const baseTag = document.querySelector('base');
        const Domain = baseTag.getAttribute('href');
        shareOnFacebook(`https://446.hu/post/${post.id}`, '446 Bejegyzés', 'Ez egy teszt bejegyzés');
    }
    useEffect(() => { 
        fetchPostMeta(); 
    },[])

    /*useEffect(() => {
        console.log(post);
    },[post]);*/

    useEffect(() => {
        if(post.atts !== null && post.atts.length > 0){
            const updatedAtts = post.atts.map(att => ({
                src: att,
                alt: "OK",
                title: "Csatolmány"
            }));
            setAtts(updatedAtts);
        };
    },[post.atts])
    
    if(isLoading){ return <LoadingIndicator /> }
    try{
    return (
        <Row className="mb-3">
            <Col md={12} xs={12} className="feedItem">
                <Row>
                    <Col md={1} xs={2}>
                        <TBUserPicture user={poster} showName={false} w={38} h={38} /> 
                    </Col>
                    <Col md={8} xs={8}>
                        <b><Link to={`/profile/${poster.uid}`} className="feedLink">{poster.username}</Link></b>
                        {(target) && (
                            <><b> <FaIcon type="solid" icon="caret-right"/> <Link to={`/profile/${target.uid}`} className="feedLink">{target.username}</Link></b></>
                        )}
                        
                        <br/>
                        <span className="small">{getDiff(post.timestamp)} · <FaIcon type="light" icon={`${(post.visibility === "public") ? "globe" : "lock"}`} /></span>
                    </Col>
                    {(user.perm >= 97) && (
                        <Col md={3} xs={2} className="text-right">
                            <Dropdown className="fbstle-dropdown">
                                <Dropdown.Toggle variant="link" id="dropdown-post"><FaIcon type="light" icon="ellipsis"/></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item variant="dark-2" as={Button} onClick={() => { deletePost() }}>Törlés</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col md={12}>
                        <TextWithLinks text={post.content} />
                        {(post.atts !== null) && (post.atts.length > 0) && (
                            <>
                                <hr/>
                                {(atts.slice(0,6).map((image, index) => (
                                    <img src={image.src} alt={image.alt} key={index} style={{width:"128px",height:"128px",cursor:"pointer"}} onClick={() => {setLightBoxOpen(true); setLBIndex(index); }}/>
                                )))}
                                <Lightbox
                                    open={lightBoxOpen}
                                    close={() => {setLightBoxOpen(false)}}
                                    index={lbIndex}
                                    slides={atts}
                                />
                                {(atts.length > 6) && ( <Button className="w-100"><FaIcon type="light" icon="images" /> Összes kép</Button>)}
                            </>
                        )}
                    </Col>
                </Row>
                <Row style={{paddingTop:'5px',paddingBottom:'5px'}}>
                    <Col md={4} sm={4} xs={4} className="text-center">
                        <Button className={`${(iliked) ? 'iLiked' : ''} w-100`} variant="dark-feed" onClick={() => { likePost(); }}>
                            <FaIcon type="light" icon="thumbs-up"/> {likes} <span className="d-none d-sm-none d-md-inline">Tetszik</span>
                        </Button>
                    </Col>
                    <Col md={4} sm={4} xs={4} className="text-center">
                        <Button className="w-100" variant="dark-feed" onClick={handleFocus}><FaIcon type="light" icon="comment" /> <span className="d-none d-sm-none d-md-inline">Hozzászólás</span></Button>
                    </Col>
                    <Col md={4} sm={4} xs={4} className="text-center">
                        {(post.visibility === "public") && ( <Button className="w-100" variant="dark-feed" onClick={sharePost}><FaIcon type="light" icon="share" /> <span className="d-none d-sm-none d-md-block">Megosztás</span></Button> )}
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{paddingLeft:'25px',paddingRight:'25px'}}>
                        <FeedItemComments ref={inputRef} postID={post.id}/>
                    </Col>
                </Row>
                
            </Col>
        </Row>
    );
}catch(err){
    return null;
}
};

export default FeedItem;